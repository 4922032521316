<template>
  <div>
      <h2>发货单列表</h2>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>